.add-form {
    width: 70vw;
    max-width: 800px;
    padding: 2rem;
    color: #3b6ca4;
    margin: 6% auto 0 auto;
    backdrop-filter: blur(5px); background-color: rgba(255, 255, 255, 0.1); border-radius: 26px; box-shadow: 35px 35px 68px 0px rgba(59, 108, 164, 0.5), inset -8px -8px 16px 0px rgba(59, 108, 164, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255);
}


@media screen and (max-width: 1400px) {
    .add-form {
        width: 60vw;
        max-width: 600px;
        padding: 2rem;
        margin: 8% auto 0 auto;
    }
}


@media screen and (max-width: 768px) {
    .add-form {
        margin: 170px auto 0 auto;
        width: 95vw;
        padding: 1rem;
    }

    .add-form h1 {
        font-size: 1.5rem;
    }
}



.add-form label {
    display: block;
    margin-top: 15px;
    font-weight: bold;
    margin-bottom: 20px;
}

.add-form label small {
    font-weight: normal;
}

.add-form input {
    width: 100%;
    margin-top: 10px;
    padding: 5px;
    border: 1px solid #3b6ca4;
}

.add-form textarea {
    min-width: 100%;
    margin-top: 10px;
    padding: 10px;
    resize: vertical;
    border: 1px solid #3b6ca4;
}

.add-form button {
    display: inline-block;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    line-height: 2.4rem;
}

@media screen and (max-width: 768px) {
    .add-form button {
        display: block;
        height: 38px;
        line-height: 38px;
        padding: 2px 0;
    }
}


.add-form input, .add-form textarea {
    width: 100%;
    margin-top: 10px;
    padding: 5px;
}

.add-form input:focus,
.add-form textarea:focus {
    outline: none;
    background: #adc1e7;
    border-color: #3b6ca4;
}

.add-form input:active,
.add-form textarea:active {
    outline: none;
    background: #adc1e7;
    border-color: #3b6ca4;
}


