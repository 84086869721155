header {
    background-color: #ffffff;
    padding: 20px;
    color: #3b6ca4;
    display: flex;
    justify-content: space-between;
}

header h1 {
    font-weight: 700;
    font-size: 1.7rem;
}

@media screen and (max-width: 768px) {
    header h1 {
        font-weight: 700;
        font-size: 1rem;
    }
}

.search {
    display: flex;
}

.search input {
    width: 13rem;
    margin-right: 5px;
}

header .button {
    line-height: 2.4rem;
}

@media screen and (max-width: 768px) {
    header {
        display: grid;
        grid-template-columns: 1fr 4fr;
        grid-template-rows: repeat(2, 1fr);
        justify-content: center;
        align-items: center;
        gap: 5px;
    }

    .search {
        grid-column: 1/3;
        grid-row: 2/3;
    }

    .search input {
        font-size: 0.9rem;
        line-height: 38px;
        text-align: center;
        height: 38px;
    }

    header .button, .search button {
        font-size: 0.9rem;
        line-height: 38px;
        text-align: center;
        height: 38px;
        margin: 0 0 0 auto;
    }

    header .button {
        width: 90%;
    }

    .search button {
        width: 100%;
    }
}

    .link {
        color: inherit;
        text-decoration: none;
    }