button, .button {
    background-color: #3b6ca4;
    border: 0;
    border-radius: 2px;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;
    padding: 1px 35px;
    line-height: 2.1rem;
    text-decoration: none;
    cursor: pointer;
}